import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/logo_1.png'; // 로고 이미지 경로
import titleImage from '../assets/title.png'; // 타이틀 이미지 경로

const HeaderContainer = styled.header`
  background-color: #FFFFFF;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #969696;
  position: relative; // 햄버거 메뉴를 절대 위치로 배치하기 위해 상대 위치로 설정합니다.
  z-index: 1000; // 헤더가 광고 위에 오도록 설정합니다.
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  background-color: transparent;
  padding-left: 15px;
`;

const TitleImage = styled.img`
  height: 40px;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  a {
    margin: 0 10px;
    text-decoration: none;
    color: #2c3e50;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px; // 헤더 아래에 메뉴가 나타나도록 설정
    right: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    z-index: 1001; // 메뉴가 광고 위에 오도록 설정합니다.

    a {
      margin: 10px 0;
    }
  }
`;

const BurgerMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 1001; // 햄버거 메뉴가 광고 위에 오도록 설정합니다.
    span {
      height: 3px;
      width: 25px;
      background-color: #2c3e50;
      margin: 4px;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <Logo src={logo} alt="My AI Expert Logo" />
        </Link>
        <Link to="/">
          <TitleImage src={titleImage} alt="FATELY" />
        </Link>
      </LogoContainer>
      <Nav open={isOpen}>
        <Link to="/" onClick={() => setIsOpen(false)}>Home</Link>
        {/* <Link to="/faceMenu" onClick={() => setIsOpen(false)}>페이스 진단</Link> */}
        <Link to="/fortuneMenu" onClick={() => setIsOpen(false)}>사주(운세)</Link>
        {/* <Link to="/bellanceMenu" onClick={() => setIsOpen(false)}>벨런스게임</Link>*/}
        {/* <Link to="/bellanceGame" onClick={() => setIsOpen(false)}>벨런스게임 만들기</Link>*/}
      </Nav>
      <BurgerMenu onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </BurgerMenu>
    </HeaderContainer>
  );
};

export default Header;
