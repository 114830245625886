import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import LoadingModal from './GameLoadingModal';
import { Helmet } from 'react-helmet';

export const HomeContainer = styled.div`
  min-height: 100vh;
  position: relative;
  padding: 20px;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 90%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;

  &:focus {
    border-color: #FF5675;
    outline: none;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  resize: none;

  &:focus {
    border-color: #FF5675;
    outline: none;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
`;

const Button = styled.button`
  padding: 10px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const BalanceGameForm = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nickname: '',
    topic1: '',
    topic2: '',
    image: null,
    description: '',
  });
  const [imageUrl, setImageUrl] = useState('');
  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFormData({ ...formData, image: acceptedFiles[0] });
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.image) {
      const imageUploadData = new FormData();
      imageUploadData.append('file', formData.image); // 'file'로 필드 이름 수정
  
      try {
        // 1. 이미지 업로드 요청
        const uploadResponse = await axios.post(
          'https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/upload',
          imageUploadData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
  
        // 업로드된 이미지의 URL을 받음
        const uploadedImageUrl = uploadResponse.data.url; // 서버에서 반환하는 키 확인 필요
        console.log('Uploaded image URL:', uploadedImageUrl);
  
        // 2. 게임 데이터 생성 요청
        setLoading(true);
        const gameData = {
          nickname: formData.nickname,
          topic1: formData.topic1,
          topic2: formData.topic2,
          image: uploadedImageUrl, // 업로드된 이미지 URL 추가
          description: formData.description,
        };
  
        const response = await axios.post(
          'https://port-0-ai-expert-back-lxg28k1i435c7183.sel5.cloudtype.app/api/game',
          gameData
        );
  
        console.log('Game created:', response.data);
        navigate('/bellanceMenu');
      } catch (error) {
        console.error('게임 등록 중 오류가 발생했습니다.', error);
      }
    } else {
      console.error('이미지를 업로드해주세요.');
    }
  };

  return (
    <HomeContainer>
       <Helmet>
        <title>벨런스 게임 생성 - FATELY</title>
        <meta property="og:title" content="벨런스 게임 생성 - FATELY" />
        <meta property="og:description" content="FATELY에서 벨런스 게임 생성을 생성을 해보세요!" />
      </Helmet>
      {loading && <LoadingModal />}
      <FormContainer>
        <h2>벨런스 게임 만들기</h2>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="nickname"
            placeholder="닉네임"
            value={formData.nickname}
            onChange={handleChange}
            required
          />
          <TextArea
            name="topic1"
            placeholder="주제 1"
            value={formData.topic1}
            onChange={handleChange}
            required
          />
          <TextArea
            name="topic2"
            placeholder="주제 2"
            value={formData.topic2}
            onChange={handleChange}
            required
          />
          <TextArea
            name="description"
            placeholder="메인에 표시할 설명"
            value={formData.description}
            onChange={handleChange}
            required
          />
          <DropzoneContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {formData.image ? (
              <p>{formData.image.name}</p>
            ) : (
              <p>메인 사진을 여기에 드롭하거나 클릭하여 업로드하세요.</p>
            )}
          </DropzoneContainer>
          <Button type="submit">게임 만들기</Button>
        </Form>
      </FormContainer>
    </HomeContainer>
  );
};

export default BalanceGameForm;
